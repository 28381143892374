



.product-detail .details{
    margin: 2.9rem;
     display: grid;
     grid-template-columns: 1fr 1.2fr;
     gap: 3.5rem;
 }
 .product-detail .details .left{
     display: flex;
     flex-direction: column;
 
 }
 .product-detail .details .left .main{
     text-align: center;
   margin-bottom: 2rem;
     padding: 1rem;
     height: 28rem;
 }
 .product-detail .details .left .main img{
     object-fit: contain;
     height: 100%;
     width: 100%;
    
 }
 .product-detail .details .thumbnails{
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     gap: 1rem;
 }
 .product-detail .details .thumbnail{
   width: 6rem;
   height: 6rem;
   text-align: center;
     border: 3px solid #f6f6f6;
    
 
 }
 .product-detail .details .thumbnail img{
     width: 100%;
     height: 100%;
     
     object-fit: contain;
 }
 
 
 .product-detail .right .price{
     color: #dd2f6e;
     font-weight: 600;
     font-size: 1.5rem;
     margin-bottom: 2rem;
 }
 .product-detail .right h2{
     line-height: 1.2;
     margin-bottom: 2rem;
     color: #dd2f6e;
     margin-top: 4rem;
     text-transform: uppercase;
 }
 
 /* .product-detail .right div{
     display: inline-block;
     position: relative;
     z-index: 1;
 } */
 .product-detail .right select{
     width: 9rem;
     padding: 0.9rem;
     border-radius: 5rem;
     border: 2px solid #dd2f6e;
     outline: none;
     appearance: none;
     
     
 }
 .product-detail form{
     margin-bottom: 2rem;
 }
 
 
 .product-detail .form-add{
     margin-bottom: 3rem;
 }
 .product-detail .form-add input{
     width: 3.5rem;
     padding: 0.8rem;
     border-radius: 5rem;
     border: 2px solid #dd2f6e;
     outline: none;
     text-align: center;
     margin-right: 2rem;
 
 }
 .product-detail .form-add .addCart{
     background-color:#dd2f6e ;
     color: #fff;
     padding: 0.8rem 2rem;
     border-radius: 5rem;
 }
 .product-detail h3{
     color: #dd2f6e ;
     text-transform: uppercase;
     margin-bottom: 1.8rem;
     margin-top: 4rem;
 }
 .inclusion{
     color: #dd2f6e;
     margin-bottom: 1rem;
     
 }
 .inclusion .icon{
    font-size: 1rem;
 }
 .pro-details{
     margin-bottom: 4rem;
 }
 .pro-details i{
     padding-left: 1rem;
 }
 
 
 
 
 
 
 @media only screen and (max-width:996px){
     .product-detail .details .left{
         width: 30rem;
         height: 45rem;
     }    
     .product-detail .details{
         gap: 3rem;
     }
     .product-detail .details .thumbnails{
         width: 30rem;
         gap: 0.2rem;
     }
     .product-detail .details .thumbnail{
         width: auto;
         height: 10rem;
         text-align: center;
         padding: 0.5rem;
       
     }
 }
 @media only screen and (max-width:870px){
       
     .product-detail .details{
         grid-template-columns: 1fr;
         row-gap: 3rem;
     }
   
     
 }
 
 @media only screen and (max-width:650px){
     .product-detail .details{
         grid-template-columns: 1fr;
        
         margin: 1.2rem;
     }
     .product-detail .right{
         margin-top: 0rem;
     }
     .product-detail .details .left{
         width: 100%;
         height: 29rem;
     }
     .product-detail .details .thumbnails{
         width: 100%;
     }
 
 
 
 
     .product-detail .right .price{
         color: #dd2f6e;
         font-weight: 600;
         font-size: 1.2rem;
         margin-bottom: 1.5rem;
         margin-top: 0;
     }
        .product-detail .right h2{
         font-size: 1.2rem;
         line-height: 1.2;
         margin-bottom: 1.5rem;
         color: #dd2f6e;
     }
     .product-detail .details .thumbnail{
    
         height: 5rem;
         text-align: center;
         padding: 0.1rem;
       
     }
     .product-detail .right select{
      
         width: 7rem;
         padding: 0.8rem;
         border-radius: 6rem;
         border: 2px solid #dd2f6e;
         outline: none;
         appearance: none;
     
     }
        .product-detail .form-add input{
         width: 2.5rem;
         padding: 0.7rem;
         border-radius: 5rem;
         border: 2px solid #dd2f6e;
         outline: none;
         text-align: center;
         margin-right: 2rem;
     
     }
     .product-detail .form-add .addCart{
         background-color:#dd2f6e ;
         color: #fff;
         padding: 0.7rem 1rem;
         border-radius: 5rem;
         font-size: 0.9rem;
     }
     
 
 }
 
 
 
     