.footer-logo-img{
  width: 34%;
    object-fit: cover;
    border-radius: 1rem;
    margin-bottom: 23px;
    height: 100%;
}

.w3l-footer-29-main {
  background: #222; }
  .w3l-footer-29-main .footer-top-29 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr ;
    grid-gap: 40px; }
  .w3l-footer-29-main .images-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px; }
  .w3l-footer-29-main .footer-list-29 .properties a p:hover {
    color: #fff; }
  .w3l-footer-29-main .footer-list-29 a p {
    color: #aaa;
    font-size: 16px;
    line-height: 25px; }
  .w3l-footer-29-main h6.footer-title-29,.w3l-footer-29-main h2.footer-title-29{
    color: #fff;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    margin-bottom: 25px;
    font-weight: 600; }
  .w3l-footer-29-main .footer-list-29 ul li,
  .w3l-footer-29-main .midd-footer-29:nth-child(1) .footer-list-29 ul li:last-child {
    list-style: none;
    margin-bottom: 10px; }
  .w3l-footer-29-main .footer-list-29 ul li:last-child {
    margin-bottom: 0px; }
  .w3l-footer-29-main .footer-list-29 ul li a, .w3l-footer-29-main .footer-list-29 ul li p {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #aaa; }
  .w3l-footer-29-main .footer-list-29 ul li a:hover {
    color: #eee; }
  .w3l-footer-29-main .main-social-footer-29 a {
    margin-right: 10px;
    color: #777;
    background: #252525;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    transition: 0.3s; }
  .w3l-footer-29-main .main-social-footer-29 a:hover {
    transition: 0.3s; }
  .w3l-footer-29-main .main-social-footer-29 a.facebook:hover {
    background: #3b5998;
    color: #fff; }
  .w3l-footer-29-main .main-social-footer-29 a.twitter:hover {
    background: #1da1f2;
    color: #fff; }
  .w3l-footer-29-main .main-social-footer-29 a.instagram:hover {
    background: #c13584;
    color: #fff; }
  .w3l-footer-29-main .main-social-footer-29 a.linkedin:hover {
    background: #0077b5;
    color: #fff; }
  .w3l-footer-29-main .main-social-footer-29 a.google-plus:hover {
    background: #dd4b39;
    color: #fff; }
  .w3l-footer-29-main .footer-list-29 ul li span.fa {
    width: 20px; }
  .w3l-footer-29-main .main-social-footer-29 a:last-child {
    margin-right: 0; }
  .w3l-footer-29-main .copy-footer-29, .w3l-footer-29-main p.sub-paragraph {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #aaa; }
  .w3l-footer-29-main .copy-footer-29 a {
    color: #ccc; }
  .w3l-footer-29-main .copy-footer-29 a:hover {
    color: #fff; }
  .w3l-footer-29-main ul.list-btm-29,
  .w3l-footer-29-main p.right-side-con {
    text-align: right; }
  .w3l-footer-29-main .list-btm-29 li {
    list-style-type: none;
    display: inline-block; }
  .w3l-footer-29-main ul.list-btm-29 li:nth-child(2) {
    margin: 0 15px; }
  .w3l-footer-29-main .list-btm-29 li a,
  .w3l-footer-29-main .list-btm-29 li select {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #aaa; }
  .w3l-footer-29-main .list-btm-29 li a:hover,
  .w3l-footer-29-main .list-btm-29 li select:hover {
    color: #fff; }
  .w3l-footer-29-main .list-btm-29 li select {
    border: none;
    cursor: pointer;
    background: transparent; }
  .w3l-footer-29-main .bottom-source {
    grid-template-columns: .33fr 1fr 1fr .5fr;
    grid-gap: 100px;
    margin-top: 60px; }
  .w3l-footer-29-main .main-social-footer-29 {
    margin-top: 20px; }
  .w3l-footer-29-main .bottom-copies {
    border-top: 1px solid #454545;
    padding-top: 2em;
    margin-top: 2em; }
  @media (max-width: 1366px) {
    .w3l-footer-29-main .bottom-source {
      grid-template-columns: .4fr 1.5fr 1.5fr .5fr; } }
  @media (max-width: 1080px) {
    .w3l-footer-29-main .footer-29 {
      padding: 50px 0 70px; } }
  @media (max-width: 1080px) {
    .w3l-footer-29-main .midd-footer-29 {
      margin-top: 6em; }
    .w3l-footer-29-main .bottom-source {
      grid-template-columns: .6fr 1.5fr 2fr .5fr;
      grid-gap: 60px; } }
  @media (max-width: 1024px) {
    .w3l-footer-29-main .grid-col-2 {
      grid-template-columns: 1fr; }
    .w3l-footer-29-main .copy-footer-29 {
      text-align: center; }
    .w3l-footer-29-main ul.list-btm-29 {
      text-align: center;
      margin-top: 10px; } }
  @media (max-width: 991px) {
    .w3l-footer-29-main .footer-top-29 {
      grid-template-columns: 2fr 1fr;
      grid-gap: 40px; } }
  @media (max-width: 667px) {
    .w3l-footer-29-main .footer-29 {
      padding: 40px 0 50px; }
    .w3l-footer-29-main .main-social-footer-29 a {
      margin-right: 5px; }
    .w3l-footer-29-main ul.list-btm-29 li:nth-child(2) {
      margin: 0 18px; }
    .w3l-footer-29-main .midd-footer-29 {
      margin-top: 5em;
      padding-bottom: 1.5em;
      margin-bottom: 1.5em; }
    .w3l-footer-29-main ul.list-btm-29 li:nth-child(2) {
      margin: 0 12px; } }
  @media (max-width: 600px) {
    .w3l-footer-29-main .footer-top-29 {
      grid-template-columns: 1fr 1fr; }
    .w3l-footer-29-main .midd-footer-29,
    .w3l-footer-29-main ul.list-btm-29 {
      grid-template-columns: 1fr;
      text-align: center;
      grid-gap: 20px; }
      .footer-logo-img{
        width: 64%;
         
      }
   }
  @media (max-width: 480px) {
    .w3l-footer-29-main .grid-1-footer:nth-child(1) {
      grid-column-start: span 2; }
    .w3l-footer-29-main .footer-1 {
      grid-column: span 2; }
    .w3l-footer-29-main .footer-3 {
      grid-column: span 2;
      order: 2; } }
  @media (max-width: 440px) {
    .w3l-footer-29-main .footer-top-29 {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 384px) {
    .w3l-footer-29-main .footer-top-29 {
      grid-gap: 40px;
      grid-template-columns: 1fr 1fr; }
    .w3l-footer-29-main .midd-footer-29 {
      margin-top: 4em; }
    .w3l-footer-29-main .bottom-source {
      margin-top: 40px; } }