.appContainer{
   
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boxContainer{
    margin-top: 4rem;
    width: 400px;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(15,15,15,0.28);
    position: relative;
    overflow: hidden;

}
.topContainer{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1.8rem;
    padding-bottom: 5em;

}
.innerContainer{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.backDrop{
    width: 160%;
    height: 550px;
    position: absolute;
    display: flex;
    top: -290px;
    left: -70px;
    flex-direction: column;
    border-radius: 50%;
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(207,255,0,1) 0%, rgba(0,212,255,1) 100%);

}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

}
.headerText{
    font-size: 30px;
    font-weight: 600;
    z-index: 10;
    color: #fff;
    letter-spacing: 2px;
  
}
.smallText{
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    z-index: 10;
    opacity: .9;
    letter-spacing: 2px;
    margin: 0;
    margin-top: 6px;
}

.formContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

}
.alink{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.mutedLink{
    font-size: 15px;
    color: rgba(200, 200, 200, .8);
    font-weight: 500;
    text-decoration: none;
}
.boldLink{
    font-size: 15px;
    color: rgb(241, 196, 15);
    font-weight: 500;
    text-decoration: none;
}
.input{
    width: 100%;
    height: 42px;
    outline: none;
    border: 1px solid rgba(200, 200, 200, 0.3);
    padding: 0px 10px;
 
    border-radius: 2rem;
    margin-bottom: 1rem;
}
.input:focus{
    outline: none;
    border-bottom: 2px solid rgba(200, 200,200, .8);
}
.input::placeholder{
    color: rgba(200, 200, 200, 1);
}

.submitButton{
    margin-top: 5px;
    width: 100%;
    padding: 10px 30%;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 100px 100px 100px 100px;
    cursor: pointer;
    transition: all 240ms  ease-in-out;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(207,255,0,1) 0%, rgba(0,212,255,1) 100%);
    
}
.submitButton:hover{
    filter: brightness(1.03);
}

@media screen and (max-width:660px) {
    .boxContainer{
        width: 300px;
    }
}