:root{
    --primary: hsl(26,93%,50%);
    --hover: hsl(26,90%,42%);
    --white: hsl(0,0%,100%);
    --black: hsl(0,0%,18%);
    --text: hsl(240,1%,48%);
    --whiteDeam: hsl(0,0%,93%);
    --gerybg: hsl(0,0%,96%);
    --greytext: hsl(190,190,190);
    --inputcolor: hsl(330,12%,97%);
    --box-shadow: 0 2px 8px 2px rgba(178,178,178,0.334)
}

.secHeader{
    flex-direction: column;
   
    gap: 1rem;
}
.secTitle{
    color: var(--black);
    line-height: 1.5rem;
    padding-bottom: .5rem;
    text-align: center;
    justify-content: center;
}
p{
    font-size: 13px;
    color: var(--text);
    font-weight: 500;
}

.mainContent{
    padding: 2rem 0;
    gap: 1rem;
}
.singlDestinations{
    overflow: hidden;
    border-radius: 1.3rem;
    box-shadow: var(--box-shadow);
}
.destImage{
    height: 300px;
    width: 100%;
    position: relative;
    overflow: hidden;

}
img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
/* .overLayInfo{
    position: absolute;
    display: flex;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    padding: 0;
   justify-content: center;
   flex-direction: column;
   overflow: hidden;
   background: linear-gradient(rgba(0,0,0,0.846),rgba(0,0,0,0.759));
   bottom: 0;
   width: 100%;
   height: 100%;
   height: 0;

} */
h3{
    color: var(--white);
    font-weight: 600;
}

.destFooter{
    padding: 1rem;
}
.number{
    font-size: 2rem;
    font-weight: 700;
    color: var(--black);
    position: relative;
    width: max-content;
}
.number::after{
    content: '';
    position: absolute;
    background: var(--primary);
    top: 45%;
    right: -150%;
    height: 5px;
    width: 45px;
    border-radius: 1rem;
}

.destText{
    justify-content: space-between;
}
h6{
    color: var(--black);
    font-size: 15px;

}
/* .flex{
    font-size: 16px;
    font-weight: 600;
    align-items: flex-start;
    transform: translateY(5px);
} */
/* .destFooter:hover .overLayInfo{
   overflow: visible;
   padding: 1rem 1.5rem;
   height: 100%;
} */