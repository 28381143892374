.edit-form{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 100px;
}
.contact-box{
    max-width: 850px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    
}
.contact-box h2{
    position: relative;
    padding: 0 0 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.contact-box h2:after{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: orangered;
}
.contact-box .field{
    width: 100%;
    border: 2px solid rgba(0,0,0,0);
    outline: none;
    background-color: rgba(230, 230, 230, 0.6);
    padding: .5rem 1rem;
    font-size: 1.1rem;
    margin-bottom: 22px;
    transition: .3s;
}
.contact-box .field:hover{
    background-color: rgba(0,0,0,0.1);
}
.contact-box .field:focus{
 border: 2px solid rgba(30,85,250,0.47);
}
