.Hheader{
    padding: 2rem 0rem;
    position: absolute;
    z-index: 1;
    left: 19%;

}

.Hheader {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Hheader li{
    margin:  0 1rem;
    font-size: 1.1rem;
    color: #000;
}

.Hheader .logos{
    color: rgba(38, 29, 29, 0.305);
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.Hheader .social-links ul li{
    border: 2px solid;
    width: 50px;
    height: 50px;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Hheader .social-links ul,
.Hheader .info ul{

    justify-content: space-evenly;
}


.social-type{
    color: #000;
    font-size: 1.5rem;
}
.info li{
   
    letter-spacing: 1px;
    font-size: 1.5rem;

}
.social-type-call{
    color: #000;
    font-size: 1.5rem;

}


@media (max-width:500px) {
    .Hheader .d-flex{
        flex-direction: column;
    }
    .social-links .d-flex{
        flex-direction: row;
        margin: 4px 0;
    }
    .info .d-flex{
        flex-direction: row;
        margin: 4px 0;

    }
    .info li{
        color: #222;
        font-size: .8rem;
        letter-spacing: 2px;
        font-weight: bold;
    }
    .social-type-call{
        font-size: .6rem;
    }
    .social-type{
        font-size: 1rem;
    }
    .Hheader .logos{
        font-size: .9rem;
    }
    .Hheader{
        left: 0;
    }
}
